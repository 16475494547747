import config from "@/config";
import { requests } from ".";
interface BlogSiteListByLocation {
  locationId: string;
  limit: number;
  skip: number;
  searchTerm?: string;
}

interface UpdateFunnelSettingsParams {
  locationId: string;
  funnelId: string;
  funnelName: string;
  funnelPath: string;
  domainId: string;
  faviconUrl: string;
  headTrackingCode: string;
  bodyTrackingCode: string;
  paymentMode: boolean;
  chatWidget: boolean;
  imageOptimization: boolean;
  description?: string;
}

interface FunnelStepOrPageUniqueUrlParams {
  domain: string;
  path: string;
  name: string;
  type: string;
}

interface UpdateFunnelLookupParams {
  path: string;
}

interface UpdateFunnelPageParams {
  locationId?: string;
  name?: string;
  originId?: string;
  url?: string;
  templateType?: string;
  popups?: Array<any>;
  colors?: Array<any>;
  settings?: object;
  general?: object;
  meta?: object;
  trackingCode?: object;
  pageStyles?: string;
  pagePreview?: string;
  snapshotPreview?: string;
  funnelId?: string;
  stepId?: string;
  firstFetch?: boolean;
  importedFrom?: string;
  pageDataUrl?: string;
  pageDataDownloadUrl?: string;
  pageVersion?: number;
  sectionUrl?: string;
  sectionDownloadUrl?: string;
  version?: number;
  sectionVersion?: number;
  deleted?: boolean;
  restorePage?: boolean;
  versionHistory?: any;
}

interface CreateBlogParams {
  name: string;
  type: string;
  locationId: string;
  homePath: string;
  domainId?: string;
  description?: string;
}

interface DeleteFunnelParams {
  locationId: string;
  funnelId: string;
  userId: string;
}

interface SavePageDataParams {
  pageData: any;
  funnelId: string;
  pageVersion: number;
}

interface FetchFunnelPagesWithBlogEmbedded {
  locationId: string;
}
export interface Page {
  lookupId: string;
  pageId: string;
  domain: string;
  path?: string;
}

export interface CreateFunnelLookupParams {
  funnelId: string;
  path: string;
  locationId: string;
  domain: string;
  type: string;
  typeId: string;
}

export interface FunnelLookup {
  _id: string;
  locationId: string;
  funnelId: string;
  domain: string;
  path: string;
  type: string;
  typeId: string;
  pathLowercase: string;
  action: string;
  target: string;
  deleted: boolean;
  type_id?: string; // lookup create API response doesn't have typeId instead we get type_id
}

export interface GetLookupByDomainAndPath {
  domain: string;
  path: string;
  locationId: string;
}

export const FunnelService = {
  getFunnelDomainById: (id: string) =>
    requests(config.FUNNEL_SERVICE).get(`/funnel/fetch-funnel-domain/${id}`),
  generateSitemap: (id: string) =>
    requests(config.FUNNEL_SERVICE).get(`/domain/generate-sitemap-blog?domain=${id}`),
  getBlogs: (params: BlogSiteListByLocation) =>
    requests(config.FUNNEL_SERVICE).get(`/funnel/blog/list/`, { params }),
  GetBlogById: (id: string) => requests(config.FUNNEL_SERVICE).get(`/funnel/fetch/${id}`),
  GetPageDataById: (id: string) => requests(config.FUNNEL_SERVICE).get(`/page/${id}`),
  GetDomainList: (id: string) => requests(config.FUNNEL_SERVICE).get(`/domain/?locationId=${id}`),
  updateFunnelSettings: (params: UpdateFunnelSettingsParams) =>
    requests(config.FUNNEL_SERVICE).post(`/funnel/update-settings`, params),
  getUniqueFunnelStepOrPageUrl: (params: FunnelStepOrPageUniqueUrlParams) =>
    requests(config.FUNNEL_SERVICE).get(`/funnel/funnel-step-page-url`, { params }),
  getLookupByTypeId: (id: string) => requests(config.FUNNEL_SERVICE).get(`/lookup/type/${id}`),
  updateFunnelLookup: (params: { lookupId: string; payload: UpdateFunnelLookupParams }) =>
    requests(config.FUNNEL_SERVICE).put(`/lookup/${params.lookupId}`, params.payload),
  updateFunnelPage: (params: { pageId: string; payload: UpdateFunnelPageParams }) =>
    requests(config.FUNNEL_SERVICE).post(`/funnel/funnel-page/${params.pageId}`, params.payload),
  createBlog: (params: CreateBlogParams) =>
    requests(config.FUNNEL_SERVICE).post(`/funnel/create-blog`, params),
  deleteBlog: (params: DeleteFunnelParams) =>
    requests(config.FUNNEL_SERVICE).post(`/funnel/delete`, params),
  savePageData: (pageID: string, params: SavePageDataParams) =>
    requests(config.FUNNEL_SERVICE).post(`/builder/save-page-data/${pageID}`, params),
  fetchPagesWithBlogElement: (params: FetchFunnelPagesWithBlogEmbedded) =>
    requests(config.FUNNEL_SERVICE).get<{ pages: Page[]; traceId: string }>(
      `/funnel/pages-with-blog`,
      { params }
    ),
  createFunnelLookup: (params: CreateFunnelLookupParams) =>
    requests(config.FUNNEL_SERVICE).post(`/lookup/create`, params),
  getLookupByDomainAndPath: (params: GetLookupByDomainAndPath) =>
    requests(config.FUNNEL_SERVICE).get<{ unique: boolean }>(`/lookup/funnel-unique-url`, {
      params,
    }),
};
