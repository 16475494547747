<template>
  <div id="app-container" class="relative z-0">
    <div id="blogs-container" class="relative z-10">
      <UIContentWrap :full-screen="true" :locale="(appStore.locale as any)">
        <div v-if="authenticatedUser" class="app-container antialiased">
          <Main />
        </div>
        <div v-else class="flex h-screen p-80">
          <div class="m-auto">
            <UISpin size="large" />
          </div>
        </div>
      </UIContentWrap>
    </div>
    <div id="modal-teleport" class="absolute z-20"></div>
    <div id="media-teleport" class="absolute z-30"></div>
  </div>
</template>

<script setup lang="ts">
import { UIContentWrap, UISpin } from "@gohighlevel/ghl-ui";
import Postmate from "postmate";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Main from "./Main.vue";
import { emiterFor, useEmitter } from "./composition/useEmitter";
import { useAppStore } from "./store/app";
import { QuickActionSubmitOptions } from "./types";
import { setupPendo } from "./util";
import { setSentryMeta } from "./util/setupSentry";

const i18n = useI18n();
const appStore = useAppStore();
const route = useRoute();

const authenticatedUser = ref(false);
const emitter = useEmitter();
onMounted(() => {
  if (process.env.NODE_ENV === "development") {
    Postmate.debug = true;
  }
  const handshake = new Postmate.Model({
    "updated-token": (token: string) => appStore.refreshToken(token),
    "selected-content-ai-variation": (id: string) => {
      localStorage.setItem(`blog_content_ai_${route.params.postId}`, id);
      emitter.emit(emiterFor.fetchSelectedContent, id);
    },
    selectedMediaFile: (file: any) => {
      emitter.emit("mediaSelected", file);
    },
    "selected-image-ai-variation": (id: string) => {
      localStorage.setItem(`blog_image_ai_${route.params.postId}`, id);
      emitter.emit(emiterFor.fetchSelectedImage, id);
    },
    "selected-action-generated-session": (data: {
      submitType: QuickActionSubmitOptions;
      quickActionSessionId: string;
    }) => {
      emitter.emit(emiterFor.fetchSelectedQuickActionsContent, data);
    },
  });
  Postmate.debug = true;

  handshake.then(({ model }: any) => {
    setLocale(model.locale);
    appStore.initialise(model);
    appStore.setAppHandshake(handshake);
    setSentryMeta({
      userId: model.userId,
      companyId: model.companyId,
      locationId: model.locationId,
      email: model.userEmail,
      locale: model.locale,
    });
    setupPendo(model);
  });
});

const setLocale = (lang: string) => {
  localStorage.setItem("locale", lang);
  i18n.locale.value = lang as any;
};

watch(
  () => appStore.token,
  (token: string) => {
    if (token) {
      authenticatedUser.value = true;
    } else {
      authenticatedUser.value = false;
    }
  }
);
</script>
<style></style>
